<template>
  <div :class="`m-3 p-2 wrapper  wrapper--${bu}`">
    <h2 class="text-center">Verkoop orders - {{ name }}</h2>
    <Loading v-if="loading" />
    <SalesOrderTable v-if="!loading" :table_data="sales_orders" :table_data_total="sales_order_totals" :table_style="`${bu}`" :person_data="`${name}`" />
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import request from "@/functions/request.js";
import SalesOrderTable from "@/components/SalesOrderTable.vue"
import { msalInstance } from "vue-msal-browser";

export default {
  props: ["bu"],
  components: { Loading, SalesOrderTable },
  data: () => ({
    loading: true,
    name: null,
    sales_orders: null,
    sales_order_totals: null,
  }),
  created() {
    this.name = msalInstance.getAllAccounts()[0].idTokenClaims.name;
    this.getData(this.bu);
  },
  watch:{
    bu(newbu){
      this.getData(newbu);
    }
  },
  methods: {
    getData(bu) {
        this.loading = true,
      request(`sales-orders/${bu}/${this.name}`, "GET").then(
        ({ sales_orders, sales_order_totals}) => {
          this.sales_order_totals = sales_order_totals[this.name];
          this.sales_orders = sales_orders[this.name];
          this.loading = false;
        }
      );
    },
  },
};
</script>